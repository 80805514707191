.slider-container {
  width: 100vw;
  /* Chiều rộng của slider là 100% chiều rộng của màn hình */
  height: 100vh;
  /* Chiều cao của slider là 100% chiều cao của màn hình */
}

.slider-content {
  width: 100%;
  height: 100%;
}

.slick-slide img {
  width: 100%;
  /* Đảm bảo hình ảnh trong slider sẽ chiếm toàn bộ không gian của slide */
  height: 100%;
  object-fit: cover;
  /* Đảm bảo hình ảnh sẽ bao phủ toàn bộ không gian của slide mà không bị méo hoặc căng ra */
}